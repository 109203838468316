@import '/src/assets/font/borna/stylesheet.css';
@import '/src/_variables.scss';

* {
  font-family: $ff-regular !important;
  // scrollbar-width: none;
}

body {
  margin: 0;
  &::-webkit-scrollbar {
    display: none;
  }
}

body.zoom {
  zoom: var(--zoom);
}

body.mz-zoom {
  width: 390px;
  height: 969px;
  transform: scale(var(--zoom));
  transform-origin: 0 0;
}

img {
  height: auto;
}

p {
  margin: 0;
}

html, body {
  height: 100%;
  scrollbar-width: none;
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.i18n-en-title {
  font-size: $fs-32;
}

.custom-dialog-backdrop {
  background: rgba(0, 0, 0, .83);
}

.tos-bold,
.i18n-bold {
  font-weight: $fw-medium;
}

.tos-underline {
  text-decoration: underline;
}

.warning {
  span {
    text-decoration: underline;
  }
}

@include landscape {
  body.mz-zoom {
    width: 1024px;
    height: 672px;
  }
}

@include pc {
  body.mz-zoom {
    width: 1920px;
    height: 969px;
  }

  .i18n-en-title {
    font-size: $fs-80;
  }
}
