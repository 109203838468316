@font-face {
  font-family: 'Borna';
  font-weight: normal;
  font-style: normal;
  src: url('borna-regular-webfont.woff2') format('woff2'),
    url('borna-regular-webfont.woff') format('woff'),
    url('borna-regular-webfont.ttf') format('truetype'),
    url('borna-regular-webfont.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Borna';
  font-weight: normal;
  font-style: italic;
  src: url('borna-regularitalic-webfont.woff2') format('woff2'),
    url('borna-regularitalic-webfont.woff') format('woff'),
    url('borna-regularitalic-webfont.ttf') format('truetype'),
    url('borna-regularitalic-webfont.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Borna';
  font-weight: 500;
  font-style: normal;
  src: url('borna-medium-webfont.woff2') format('woff2'),
    url('borna-medium-webfont.woff') format('woff'),
    url('borna-medium-webfont.ttf') format('truetype'),
    url('borna-medium-webfont.eot');
}

@font-face {
  font-family: 'Borna';
  font-weight: 500;
  font-style: italic;
  src: url('borna-mediumitalic-webfont.woff2') format('woff2'),
    url('borna-mediumitalic-webfont.woff') format('woff'),
    url('borna-mediumitalic-webfont.ttf') format('truetype'),
    url('borna-mediumitalic-webfont.eot');
}

@font-face {
  font-family: 'Borna';
  font-weight: 600;
  font-style: normal;
  src: url('borna-semibold-webfont.woff2') format('woff2'),
    url('borna-semibold-webfont.woff') format('woff'),
    url('borna-semibold-webfont.ttf') format('truetype'),
    url('borna-semibold-webfont.eot');
}

@font-face {
  font-family: 'Borna';
  font-weight: 600;
  font-style: italic;
  src: url('borna-semibolditalic-webfont.woff2') format('woff2'),
    url('borna-semibolditalic-webfont.woff') format('woff'),
    url('borna-semibolditalic-webfont.ttf') format('truetype'),
    url('borna-semibolditalic-webfont.eot');
}

@font-face {
  font-family: 'Borna';
  font-weight: 700;
  font-style: normal;
  src: url('borna-bold-webfont.woff2') format('woff2'),
    url('borna-bold-webfont.woff') format('woff'),
    url('borna-bold-webfont.ttf') format('truetype'),
    url('borna-bold-webfont.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Borna';
  font-weight: 700;
  font-style: italic;
  src: url('borna-bolditalic-webfont.woff2') format('woff2'),
    url('borna-bolditalic-webfont.woff') format('woff'),
    url('borna-bolditalic-webfont.ttf') format('truetype'),
    url('borna-bolditalic-webfont.eot') format('embedded-opentype');
}
