// Font family
$ff-regular: 'Borna';

//Font weight
$fw-normal: 400;
$fw-medium: 500;
$fw-semi: 600;
$fw-bold: 700;

//Font size
$fs-6: 0.375rem;
$fs-7: 0.438rem;
$fs-8: 0.5rem;
$fs-9: 0.563rem;
$fs-10: 0.625rem;
$fs-11: 0.688rem;
$fs-12: 0.75rem;
$fs-13: 0.813rem;
$fs-14: 0.875rem;
$fs-15: 0.938rem;
$fs-16: 1rem;
$fs-17: 1.063rem;
$fs-18: 1.125rem;
$fs-19: 1.188rem;
$fs-20: 1.25rem;
$fs-21: 1.313rem;
$fs-22: 1.375rem;
$fs-23: 1.438rem;
$fs-24: 1.5rem;
$fs-25: 1.563rem;
$fs-26: 1.625rem;
$fs-27: 1.688rem;
$fs-28: 1.75rem;
$fs-29: 1.813rem;
$fs-30: 1.875rem;
$fs-32: 2rem;
$fs-35: 2.188rem;
$fs-36: 2.25rem;
$fs-37: 2.313rem;
$fs-38: 2.375rem;
$fs-40: 2.5rem;
$fs-44: 2.75rem;
$fs-45: 2.813rem;
$fs-46: 2.875rem;
$fs-48: 3rem;
$fs-50: 3.125rem;
$fs-52: 3.25rem;
$fs-55: 3.438rem;
$fs-60: 3.75rem;
$fs-64: 4rem;
$fs-65: 4.063rem;
$fs-70: 4.375rem;
$fs-75: 4.688rem;
$fs-80: 5rem;
$fs-85: 5.313rem;
$fs-100: 6.25rem;
$fs-130: 8.125rem;
$fs-140: 8.75rem;
$fs-6px: 6px;
//Color
$bg-yellow: #ecc910;
$yellow: #ffd700;
$blue: #1937e2;
$gray: #ececec;
$sgray: #bababa;
$white: #fff;
$black: #000;
$sblack: #1d1d1b;
$bg-gray: #d9d9d9;
$red: #ff0000;
$green: #00cc66;
//Query
@mixin mobile {
  @media (min-width: 390px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin landscape {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin pc1280 {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin pc {
  @media (min-width: 1366px) {
    @content;
  }
}

//Variables
@mixin flex($direction: row, $content: unset, $align: unset) {
  display: flex;
  flex-direction: $direction;
  justify-content: $content;
  align-items: $align;
}

@mixin cookiesScroll {
  &::-webkit-scrollbar {
    background-color: black;
    border: 2px solid $white;
    border-radius: 10px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $blue;
  }
}

@mixin button($bg-color, $color, $width, $height, $border-radius, $font-size) {
  @include flex(row, center, center);
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-weight: $fw-medium;
  background-color: $bg-color;
  color: $color;
  border-radius: $border-radius;
  font-size: $font-size;
  width: $width;
  height: $height;
  &:active {
    transform: scale(0.95);
  }
}
